(<template>
  <div class="statistics">
    <div class="statistics__header">
      <page-data-tabs :tabs="tabs"
                      :active-tab-id="activeTab"
                      @tabchanged="setActiveTab" />
      <button class="sk-btn sk-btn--white statistics__header-export-button"
              @click="exportStatistics">{{ $gettext('Export') }}</button>
    </div>
    <filter-bar class="statistics__filters" />
    <sk-select :default-value="chartSelectText"
               :description="chartSelectText"
               :preselected-value="currentChartId"
               :items-list="charts"
               class="statistics__mob-select"
               @csvaluechanged="goToChart" />
    <setting-page-skeleton :show-mob-navigation="false">
      <sidebar-nav :charts-list="charts" />
    </setting-page-skeleton>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import FilterBar from '@/components/statistics/FilterBar';
  import SidebarNav from '@/components/statistics/ChartsNav';
  import PageDataRoot from '@/components/shared_components/page_data/PageDataRoot';
  import PageDataTabs from '@/components/shared_components/page_data/PageDataTabs';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      if (store.getters['UserInfoStore/userIsEnterpriseMember'] && !store.state.StatisticsStore.departmentsList) {
        promisesArr = [...promisesArr, store.dispatch('StatisticsStore/fetchDepartmentsList')];
      }
      if (store.getters['UserInfoStore/userIsEnterpriseMember'] && !store.state.StatisticsStore.bookersList) {
        promisesArr = [...promisesArr, store.dispatch('StatisticsStore/fetchBookersList')];
      }
      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'language')];
      }
      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'filter-bar': FilterBar,
      'sidebar-nav': SidebarNav,
      'page-data-tabs': PageDataTabs
    },
    extends: PageDataRoot,
    computed: {
      ...mapGetters('UserInfoStore', ['userIsEnterpriseMember']),
      chartsList() {
        return [{
          id: 'line_type',
          name: this.isInterpretationTab ? this.$gettext('Assignments per type') : this.$gettext('Projects per type')
        }, {
          id: 'line_department',
          name: this.isInterpretationTab ? this.$gettext('Assignments per department') : this.$gettext('Projects per department'),
          condition: this.userIsEnterpriseMember
        }, {
          id: 'line_booker',
          name: this.isInterpretationTab ? this.$gettext('Assignments per booker') : this.$gettext('Projects per booker'),
          condition: this.userIsEnterpriseMember
        }, {
          id: 'line_interpreter',
          name: this.isInterpretationTab ? this.$gettext('Assignments per interpreter') : this.$gettext('Projects per translator')
        }, {
          id: 'line_status',
          name: this.isInterpretationTab ? this.$gettext('Assignments per status') : this.$gettext('Projects per status')
        }, {
          id: 'line_language',
          name: this.isInterpretationTab ? this.$gettext('Assignments per language') : this.$gettext('Projects per language pair')
        }, {
          id: 'pie_type',
          name: this.isInterpretationTab ? this.$gettext('Assignments per type by time period') : this.$gettext('Projects per type by time period')
        }, {
          id: 'bar_department',
          name: this.isInterpretationTab ? this.$gettext('Assignments per department by time period') : this.$gettext('Projects per department by time period'),
          condition: this.userIsEnterpriseMember
        }, {
          id: 'pie_booker',
          name: this.isInterpretationTab ? this.$gettext('Assignments per booker by time period') : this.$gettext('Projects per booker by time period'),
          condition: this.userIsEnterpriseMember
        }, {
          id: 'pie_status',
          name: this.isInterpretationTab ? this.$gettext('Assignments per status by time period') : this.$gettext('Projects per status by time period')
        }, {
          id: 'pie_interpreter',
          name: this.isInterpretationTab ? this.$gettext('Assignments per interpreter by time period') : this.$gettext('Projects per translator by time period')
        }, {
          id: 'pie_language',
          name: this.isInterpretationTab ? this.$gettext('Assignments per language by time period') : this.$gettext('Projects per language pair by time period')
        }];
      },
      charts() {
        return this.chartsList.filter((chart) => {
          return chart.condition !== undefined ? chart.condition : true;
        });
      },
      tabs() {
        return [{
          id: 'interpretation',
          name: this.$gettext('Interpretation statistics'),
          link: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'interpretation'}, params: {chartId: 'line_type'}})
        }, {
          id: 'translation',
          name: this.$gettext('Translation statistics'),
          link: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'translation'}, params: {chartId: 'line_type'}})
        }];
      },
      activeTab() { return this.$route.query.view || ''; },
      currentChartId() { return this.$route.params.chartId || ''; },
      isInterpretationTab() { return this.activeTab === 'interpretation'; },
      chartSelectText() { return this.$gettext('Select chart type'); }
    },
    methods: {
      ...mapActions('StatisticsStore', ['downloadStatistics']),
      ...mapMutations('StatisticsStore', ['clearStore']),
      goToChart(chartId) {
        this.$router.push(this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: this.activeTab}, params: {chartId: chartId}}));
      },
      exportStatistics() {
        const params = {
          chartId: this.currentChartId,
          scope: this.activeTab
        };

        this.downloadStatistics(params).then((csvData) => {
          const blob = new Blob([csvData], {type: 'text/csv'});

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `${this.activeTab}-statistics.csv`;
          downloadLink.click();
        });
      },
      setActiveTab(tab) {
        this.clearStore();
        this.$router.push(tab.link);
      }
    },
    beforeRouteLeave(to, from, next) {
      this.clearStore();
      next();
    }
  };
</script>

<style scoped>
  .statistics {
    flex-direction: column;
    width: 1140px;
    max-width: 100%;
    margin: 0 auto;
    padding: 45px 0;
  }

  .statistics__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .statistics__mob-select {
    width: 100%;
    padding: 20px 15px;
  }

  .statistics__header-export-button {
    width: auto;
    padding-right: 15px;
    padding-left: 35px;
    background-image: var(--assignment-header-export-icon);
    background-position: 5% 50%;
    background-size: 22px auto;
    background-repeat: no-repeat;
  }

  @media (min-width: 768px) {
    .statistics__mob-select {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .statistics {
      padding: 0;
    }
  }
</style>
